<template>
    <!-- 通用右侧禁飞信息 -->
    <div class="information-main">
        <div class="main">
            <div class="title">
                <span>{{$t('noFlyZone.noFlyInformation')}}</span>
                <i class="el-icon-close" @click="$emit('closeInformation')"></i>
            </div>
            <div class="information">
                <div class="information-list">
                    <div class="list-title">
                        <p></p>
                        <span>{{$t('noFlyZone.basicInformation')}}</span>
                    </div>
                    <div class="list-msg">
                        <div class="label">{{$t('noFlyZone.type')}}</div>
                        <div class="msg">{{ noFlyType(noFlyData.flyZoneType)}}</div>
                    </div>
                    <div class="list-msg">
                        <div class="label">{{$t('noFlyZone.name')}}</div>
                        <div class="msg" v-if="!isCustom">{{noFlyData.name}}</div>
                        <div class="msg" v-else>
                            <el-input v-model="noFlyData.name" size="mini" @blur="saveValue" />
                        </div>
                    </div>
                    <div class="list-msg" v-if="isCustom">
                        <div class="label">{{$t('noFlyZone.startTime')}}</div>
                        <div class="msg">
                            <el-date-picker v-model="noFlyData.startTime" value-format="yyyy-MM-dd HH:mm:ss" 
                                size="mini" type="datetime" @change="saveValue('time')" />
                        </div>
                    </div>
                    <div class="list-msg" v-if="isCustom">
                        <div class="label">{{$t('noFlyZone.endTime')}}</div>
                        <div class="msg">
                            <el-date-picker v-model="noFlyData.endTime" value-format="yyyy-MM-dd HH:mm:ss" 
                                size="mini" type="datetime" @change="saveValue('time')" />
                        </div>
                    </div>
                    <div class="list-msg">
                        <div class="label">{{$t('noFlyZone.provinces')}}</div>
                        <div class="msg">
                            <el-cascader :options="areajson" :props="{expandTrigger: 'hover', checkStrictly : true}" 
                                filterable placeholder="" size="mini" v-model="provincesValue" @change="saveProvinces" />
                        </div>
                    </div>
                    <div class="list-msg" v-if="isCustom">
                        <div class="label">{{$t('noFlyZone.painter')}}</div>
                        <div class="msg">{{noFlyData.createUser}}</div>
                    </div>
                    <div class="list-msg" v-if="isCustom">
                        <div class="label">{{$t('noFlyZone.createdTime')}}</div>
                        <div class="msg">{{noFlyData.createTime}}</div>
                    </div>
                </div>
                <div class="information-list" v-if="isCustom && customShapeType === 'Point'">
                    <div class="list-title">
                        <p></p>
                        <span>{{$t('noFlyZone.centerPosition')}}</span>
                    </div>
                    <div class="list-msg">
                        <div class="label">{{$t('noFlyZone.centerLongitude')}}</div>
                        <div class="msg">
                            <el-input-number v-model="noFlyData.content.coordinates[0]" size="mini" 
                                :controls="false" @blur="saveValue('number', noFlyData.content.coordinates[0])" />
                            <span>°</span>
                        </div>
                    </div>
                    <div class="list-msg">
                        <div class="label">{{$t('noFlyZone.centerLatitude')}}</div>
                        <div class="msg">
                            <el-input-number v-model="noFlyData.content.coordinates[1]" size="mini" 
                                :controls="false" @blur="saveValue('number', noFlyData.content.coordinates[1])" />
                            <span>°</span>
                        </div>
                    </div>
                </div>
                <div class="information-list">
                    <div class="list-title">
                        <p></p>
                        <span>{{$t('noFlyZone.measuredData')}}</span>
                    </div>
                    <div class="list-msg" v-if="isCustom">
                        <div class="label">{{$t('noFlyZone.shape')}}</div>
                        <div class="msg">{{noFlyShape}}</div>
                    </div>
                    <div class="list-msg">
                        <div class="label">{{$t('noFlyZone.horizontalArea')}}</div>
                        <div class="msg" v-if="noFlyData.area >= 1000000">
                            {{noFlyData.area ? (noFlyData.area / 1000000).toFixed(2) : 0}}km^2
                        </div>
                        <div class="msg" v-else>
                            {{noFlyData.area ? (noFlyData.area).toFixed(2) : 0}}m^2
                        </div>
                    </div>
                    <div class="list-msg">
                        <div class="label">{{$t('noFlyZone.horizontalPerimeter')}}</div>
                        <div class="msg">{{noFlyData.round ? (noFlyData.round).toFixed(2) : 0}}m</div>
                    </div>
                    <div class="list-msg" v-if="isCustom && customShapeType === 'Point'">
                        <div class="label">{{$t('noFlyZone.radius')}}</div>
                        <div class="msg">
                            <el-input-number v-model="noFlyData.content.properties.radius" size="mini" 
                                :controls="false" @blur="saveValue('number-radius', noFlyData.content.properties.radius)" />
                            <span>m</span>
                        </div>
                    </div>
                </div>
                <div class="information-list information-list-point" v-if="isCustom && customShapeType === 'Polygon'">
                    <div class="list-title">
                        <p></p>
                        <span>{{$t('noFlyZone.endpointInformation')}}</span>
                    </div>
                    <div v-if="!editPoint" class="edit-point-box">
                        <div v-for="(item, index) in noFlyData.content.coordinates[0]" :key="index + item"
                            class="entity-item" v-show="index + 1 != noFlyPointLength">
                            <div class="entity-line-index">{{ index + 1 }}</div>
                            <div>[{{ item[0].toFixed(7) }}, {{ item[1].toFixed(7) }}]</div>
                            <i @click="editPointHandle(item, index)" class="el-icon-edit"></i>
                            <i @click="plusPoint(index)" class="el-icon-plus"></i>
                            <i @click.stop="deletePoint(item, index)" class="el-icon-delete"></i>
                        </div>
                    </div>
                    <div v-else class="edit-point-box">
                        <div class="entity-line-index">{{formInPonits.index + 1}}</div>
                        <el-form size="mini" :model="formInPonits" class="demo-form-inline">
                            <el-form-item :label="$t('longitude')">
                                <el-input-number size="mini" :controls="false" v-model="formInPonits.lon" />
                            </el-form-item>
                            <el-form-item :label="$t('latitude')">
                                <el-input-number size="mini" :controls="false" v-model="formInPonits.lat" />
                            </el-form-item>
                            <el-form-item>
                                <el-button @click="cancelEditHandle">{{$t('airportManager.cancelButton')}}</el-button>
                                <el-button :loading="saveLoading" type="primary" 
                                    @click="saveEditHandle">{{$t('airportManager.saveButton')}}</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import areajson from '@/assets/mapJson/areajson';
// 禁飞区类型
const noFlyDataTypes = [
    {type: 1, label: 'noFlyZone.noFly'},
    {type: 2, label: 'noFlyZone.temporary'},
    {type: 3, label: 'noFlyZone.heightLimit'},
    {type: 4, label: 'noFlyZone.bufferZone'},
    {type: 5, label: 'noFlyZone.customZone'}
];

export default {
    name: 'general-information',
    props: {
        // 禁飞区信息
        informationData: {
            type: Object,
            default: function() {
                return {};
            }
        },
        // 是否自定义禁飞区
        isCustom: {
            type: Boolean,
            default: false
        },
        // 自定义绘制禁飞区类型，Polygon为多边形，Point为圆形
        customShapeType: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            // 省市下拉数据
            areajson: areajson.areajson,
            // 禁飞信息
            noFlyData: {},
            editPoint: false, // 编辑端点弹窗
            saveLoading: false, // 保存loading
            // 正在进行编辑的端点信息（端点集合下标，经纬度）
            formInPonits: {
                index: 0,
                lon: '',
                lat: ''
            }
        }
    },
    computed: {
        noFlyType() {
            return function(val) {
                let item = noFlyDataTypes.find(t => t.type == val);
                if (item) {
                    return this.$t(item.label);
                }
                return '--';
            }
        },
        noFlyShape() {
            if (!this.customShapeType) return '--';
            let shapeType = this.customShapeType === 'Polygon' ? this.$t('noFlyZone.drawingPolygon') 
                : this.$t('noFlyZone.drawingCircle');
            return shapeType;
        },
        provincesValue() {
            let provinces = [];
            if (this.noFlyData.provinceCode) {
                provinces.push(this.noFlyData.provinceCode);
                if (this.noFlyData.cityCode) provinces.push(this.noFlyData.cityCode);
            }
            this.noFlyData.provinces = provinces;
            return provinces;
        },
        noFlyPointLength() {
            return this.noFlyData.content.coordinates[0].length;
        }
    },
    watch: {
        'informationData': {
            deep: true,
            handler: function(val) {
                this.noFlyData = val ? JSON.parse(JSON.stringify(val)) : {};
            }
        }
    },
    created() {
        this.noFlyData = this.informationData ? JSON.parse(JSON.stringify(this.informationData)) : {};
    },
    methods: {
        // 属性面板所有的数据变更都走saveValue函数进行转发调用接口保存
        saveValue(type, value, isPolygon, isDeletePoint, changeData) {
            if (type === 'number' && !isPolygon) {
                if (!value) return this.$message.warning('数值不能为空!');
                if (value && Number(value) < 1) return this.$message.warning('数值不能小于1!');
            }
            if (type === 'number-radius') {
                if (!value) return this.$message.warning('半径不能为空!');
                if (value && Number(value) < 5) return this.$message.warning('半径不能小于5!');
            }
            if (type === 'time') {
                if (this.noFlyData.startTime || this.noFlyData.endTime) {
                    if (!this.noFlyData.startTime) return this.$message.warning('请选择开始时间!');
                    if (!this.noFlyData.endTime) return this.$message.warning('请选择结束时间!');
                    if (new Date(this.noFlyData.startTime).getTime() >= new Date(this.noFlyData.endTime).getTime()) {
                        return this.$message.warning('开始时间应小于结束时间!');
                    }
                }
            }
            let changePoint = type === 'number' ? true : false;
            let noFlyData = isDeletePoint ? changeData || this.noFlyData : this.noFlyData;
            let changerRadius = type === 'number-radius' ? true : false;
            this.$emit('changeValue', noFlyData, (responseType) => {
                if (responseType === 'error') {
                    this.saveLoading = false;
                    return;
                }
                this.cancelEditHandle();
            }, changePoint, isDeletePoint, changerRadius);
        },
        // 修改省份
        saveProvinces(val) {
            this.noFlyData.provinces = val;
            this.$emit('changeValue', this.noFlyData);
        },
        // 打开编辑端点
        editPointHandle(item, index) {
            this.saveLoading = false;
            this.formInPonits = {
                index,
                lon: item[0],
                lat: item[1]
            };
            this.editPoint = true;
        },
        // 增加端点
        plusPoint(index) {
            this.$emit('addPoint', {...this.noFlyData, insertPointIndex: index});
        },
        // 删除端点
        deletePoint(item, index) {
            if (this.noFlyData.content.coordinates[0].length <= 4) {
                this.$message.warning(this.$t("airportManager.cannotdeleted"));
                return;
            }
            let point = this.noFlyData.content.coordinates[0][index];
            if (point[0] === item[0] && point[1] === item[1]) {
                let noFlyData = JSON.parse(JSON.stringify(this.noFlyData));
                noFlyData.content.coordinates[0].splice(index, 1);
                this.saveValue('number', '', true, true, noFlyData);
            }
        },
        // 关闭修改端点弹窗
        cancelEditHandle() {
            this.editPoint = false;
            this.saveLoading = false;
            this.formInPonits = {
                index: 0,
                lon: '',
                lat: ''
            };
        },
        // 端点信息修改保存
        saveEditHandle() {
            if (!this.formInPonits.lon || !this.formInPonits.lat) {
                return this.$message.warning('数值不能为空!');
            }
            if (Number(this.formInPonits.lon) < 1 || Number(this.formInPonits.lat) < 1) {
                return this.$message.warning('数值不能小于1!');
            }
            let lon = this.formInPonits.lon ? Number(this.formInPonits.lon) : 1;
            let lat = this.formInPonits.lat ? Number(this.formInPonits.lat) : 1;
            this.noFlyData.content.coordinates[0][this.formInPonits.index] = [lon, lat];
            if (this.formInPonits.index === 0) {
                let length = this.noFlyData.content.coordinates[0].length;
                this.noFlyData.content.coordinates[0][length - 1] = [lon, lat];
            }
            this.saveLoading = true;
            this.saveValue('number', '', true);
        }
    }
}
</script>

<style lang="scss" scoped>
.information-main {
    width: 324px;
    position: fixed;
    z-index: 100;
    top: 93px;
    bottom: 0;
    right: 0;
    background: #1E222A;
    .main {
        height: 100%;
        display: flex;
        flex-direction: column;
        .title {
            text-align: center;
            border-bottom: 1px solid #030303;
            color: #FFFFFF;
            padding: 9px 0;
            position: relative;
            span {
                font-weight: bold;
                font-size: 14px;
            }
            .el-icon-close {
                font-size: 20px;
                position: absolute;
                right: 10px;
                top: 11px;
                cursor: pointer;
            }
        }
        .information {
            height: 0;
            display: flex;
            flex: 1;
            flex-direction: column;
            overflow-y: auto;
            .information-list {
                display: flex;
                padding: 15px 10px;
                flex-direction: column;
                border-top: 1px solid #030303;
                &:first-child {
                    border: none;
                }
                &.information-list-point {
                    height: 0;
                    flex: 1;
                }
                .list-title {
                    display: flex;
                    align-items: center;
                    p {
                        width: 4px;
                        height: 14px;
                        background: #265EDD;
                    }
                    span {
                        font-weight: bold;
                        font-size: 14px;
                        color: #FFFFFF;
                        margin-left: 6px;
                    }
                }
                .list-msg {
                    display: flex;
                    align-items: center;
                    padding-top: 13px;
                    .label {
                        width: 110px;
                        font-size: 14px;
                        color: #FFFFFF;
                        margin-right: 5px;
                    }
                    .msg {
                        display: flex;
                        flex: 1;
                        align-items: center;
                        font-size: 14px;
                        color: #FFFFFF;
                        ::v-deep .el-input, .el-input-number {
                            width: 100%;
                            .el-input__inner {
                                background-color: #030303;
                                border: none;
                                color: #FFFFFF;
                                font-size: 14px;
                                text-align: left;
                            }
                        }
                        span {
                            margin-left: 5px;
                        }
                    }
                }
                .edit-point-box {
                    height: 0;
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    color: #ffffff;
                    margin-top: 10px;
                    overflow: auto;
                    .entity-item {
                        display: flex;
                        align-items: center;
                        margin-top: 15px;
                        & > div {
                            display: inline-block;
                            vertical-align: middle;
                            font-size: 14px;
                            margin-right: 6px;
                        }
                        & > i {
                            font-size: 12px;
                            margin-right: 4px;
                            cursor: pointer;
                        }
                        & > i:hover {
                            background-color: #409eff;
                        }
                    }
                    .entity-line-index {
                        width: 24px;
                        background: #454545;
                        border-radius: 2px;
                        opacity: 0.55;
                        text-align: center;
                        font-size: 14px;
                    }
                    ::v-deep .el-input, .el-input-number {
                        width: 100%;
                        .el-input__inner {
                            background-color: #030303;
                            border: none;
                            color: #FFFFFF;
                            font-size: 14px;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}
</style>