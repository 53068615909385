var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "information-main" }, [
    _c("div", { staticClass: "main" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("noFlyZone.noFlyInformation")))]),
        _c("i", {
          staticClass: "el-icon-close",
          on: {
            click: function ($event) {
              return _vm.$emit("closeInformation")
            },
          },
        }),
      ]),
      _c("div", { staticClass: "information" }, [
        _c("div", { staticClass: "information-list" }, [
          _c("div", { staticClass: "list-title" }, [
            _c("p"),
            _c("span", [_vm._v(_vm._s(_vm.$t("noFlyZone.basicInformation")))]),
          ]),
          _c("div", { staticClass: "list-msg" }, [
            _c("div", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("noFlyZone.type"))),
            ]),
            _c("div", { staticClass: "msg" }, [
              _vm._v(_vm._s(_vm.noFlyType(_vm.noFlyData.flyZoneType))),
            ]),
          ]),
          _c("div", { staticClass: "list-msg" }, [
            _c("div", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("noFlyZone.name"))),
            ]),
            !_vm.isCustom
              ? _c("div", { staticClass: "msg" }, [
                  _vm._v(_vm._s(_vm.noFlyData.name)),
                ])
              : _c(
                  "div",
                  { staticClass: "msg" },
                  [
                    _c("el-input", {
                      attrs: { size: "mini" },
                      on: { blur: _vm.saveValue },
                      model: {
                        value: _vm.noFlyData.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.noFlyData, "name", $$v)
                        },
                        expression: "noFlyData.name",
                      },
                    }),
                  ],
                  1
                ),
          ]),
          _vm.isCustom
            ? _c("div", { staticClass: "list-msg" }, [
                _c("div", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("noFlyZone.startTime"))),
                ]),
                _c(
                  "div",
                  { staticClass: "msg" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                        size: "mini",
                        type: "datetime",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.saveValue("time")
                        },
                      },
                      model: {
                        value: _vm.noFlyData.startTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.noFlyData, "startTime", $$v)
                        },
                        expression: "noFlyData.startTime",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.isCustom
            ? _c("div", { staticClass: "list-msg" }, [
                _c("div", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("noFlyZone.endTime"))),
                ]),
                _c(
                  "div",
                  { staticClass: "msg" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                        size: "mini",
                        type: "datetime",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.saveValue("time")
                        },
                      },
                      model: {
                        value: _vm.noFlyData.endTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.noFlyData, "endTime", $$v)
                        },
                        expression: "noFlyData.endTime",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "list-msg" }, [
            _c("div", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("noFlyZone.provinces"))),
            ]),
            _c(
              "div",
              { staticClass: "msg" },
              [
                _c("el-cascader", {
                  attrs: {
                    options: _vm.areajson,
                    props: { expandTrigger: "hover", checkStrictly: true },
                    filterable: "",
                    placeholder: "",
                    size: "mini",
                  },
                  on: { change: _vm.saveProvinces },
                  model: {
                    value: _vm.provincesValue,
                    callback: function ($$v) {
                      _vm.provincesValue = $$v
                    },
                    expression: "provincesValue",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm.isCustom
            ? _c("div", { staticClass: "list-msg" }, [
                _c("div", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("noFlyZone.painter"))),
                ]),
                _c("div", { staticClass: "msg" }, [
                  _vm._v(_vm._s(_vm.noFlyData.createUser)),
                ]),
              ])
            : _vm._e(),
          _vm.isCustom
            ? _c("div", { staticClass: "list-msg" }, [
                _c("div", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("noFlyZone.createdTime"))),
                ]),
                _c("div", { staticClass: "msg" }, [
                  _vm._v(_vm._s(_vm.noFlyData.createTime)),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm.isCustom && _vm.customShapeType === "Point"
          ? _c("div", { staticClass: "information-list" }, [
              _c("div", { staticClass: "list-title" }, [
                _c("p"),
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("noFlyZone.centerPosition"))),
                ]),
              ]),
              _c("div", { staticClass: "list-msg" }, [
                _c("div", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("noFlyZone.centerLongitude"))),
                ]),
                _c(
                  "div",
                  { staticClass: "msg" },
                  [
                    _c("el-input-number", {
                      attrs: { size: "mini", controls: false },
                      on: {
                        blur: function ($event) {
                          return _vm.saveValue(
                            "number",
                            _vm.noFlyData.content.coordinates[0]
                          )
                        },
                      },
                      model: {
                        value: _vm.noFlyData.content.coordinates[0],
                        callback: function ($$v) {
                          _vm.$set(_vm.noFlyData.content.coordinates, 0, $$v)
                        },
                        expression: "noFlyData.content.coordinates[0]",
                      },
                    }),
                    _c("span", [_vm._v("°")]),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "list-msg" }, [
                _c("div", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("noFlyZone.centerLatitude"))),
                ]),
                _c(
                  "div",
                  { staticClass: "msg" },
                  [
                    _c("el-input-number", {
                      attrs: { size: "mini", controls: false },
                      on: {
                        blur: function ($event) {
                          return _vm.saveValue(
                            "number",
                            _vm.noFlyData.content.coordinates[1]
                          )
                        },
                      },
                      model: {
                        value: _vm.noFlyData.content.coordinates[1],
                        callback: function ($$v) {
                          _vm.$set(_vm.noFlyData.content.coordinates, 1, $$v)
                        },
                        expression: "noFlyData.content.coordinates[1]",
                      },
                    }),
                    _c("span", [_vm._v("°")]),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "information-list" }, [
          _c("div", { staticClass: "list-title" }, [
            _c("p"),
            _c("span", [_vm._v(_vm._s(_vm.$t("noFlyZone.measuredData")))]),
          ]),
          _vm.isCustom
            ? _c("div", { staticClass: "list-msg" }, [
                _c("div", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("noFlyZone.shape"))),
                ]),
                _c("div", { staticClass: "msg" }, [
                  _vm._v(_vm._s(_vm.noFlyShape)),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "list-msg" }, [
            _c("div", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("noFlyZone.horizontalArea"))),
            ]),
            _vm.noFlyData.area >= 1000000
              ? _c("div", { staticClass: "msg" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.noFlyData.area
                          ? (_vm.noFlyData.area / 1000000).toFixed(2)
                          : 0
                      ) +
                      "km^2\n                    "
                  ),
                ])
              : _c("div", { staticClass: "msg" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.noFlyData.area ? _vm.noFlyData.area.toFixed(2) : 0
                      ) +
                      "m^2\n                    "
                  ),
                ]),
          ]),
          _c("div", { staticClass: "list-msg" }, [
            _c("div", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("noFlyZone.horizontalPerimeter"))),
            ]),
            _c("div", { staticClass: "msg" }, [
              _vm._v(
                _vm._s(
                  _vm.noFlyData.round ? _vm.noFlyData.round.toFixed(2) : 0
                ) + "m"
              ),
            ]),
          ]),
          _vm.isCustom && _vm.customShapeType === "Point"
            ? _c("div", { staticClass: "list-msg" }, [
                _c("div", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("noFlyZone.radius"))),
                ]),
                _c(
                  "div",
                  { staticClass: "msg" },
                  [
                    _c("el-input-number", {
                      attrs: { size: "mini", controls: false },
                      on: {
                        blur: function ($event) {
                          return _vm.saveValue(
                            "number-radius",
                            _vm.noFlyData.content.properties.radius
                          )
                        },
                      },
                      model: {
                        value: _vm.noFlyData.content.properties.radius,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.noFlyData.content.properties,
                            "radius",
                            $$v
                          )
                        },
                        expression: "noFlyData.content.properties.radius",
                      },
                    }),
                    _c("span", [_vm._v("m")]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
        _vm.isCustom && _vm.customShapeType === "Polygon"
          ? _c(
              "div",
              { staticClass: "information-list information-list-point" },
              [
                _c("div", { staticClass: "list-title" }, [
                  _c("p"),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("noFlyZone.endpointInformation"))),
                  ]),
                ]),
                !_vm.editPoint
                  ? _c(
                      "div",
                      { staticClass: "edit-point-box" },
                      _vm._l(
                        _vm.noFlyData.content.coordinates[0],
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: index + 1 != _vm.noFlyPointLength,
                                  expression: "index + 1 != noFlyPointLength",
                                },
                              ],
                              key: index + item,
                              staticClass: "entity-item",
                            },
                            [
                              _c("div", { staticClass: "entity-line-index" }, [
                                _vm._v(_vm._s(index + 1)),
                              ]),
                              _c("div", [
                                _vm._v(
                                  "[" +
                                    _vm._s(item[0].toFixed(7)) +
                                    ", " +
                                    _vm._s(item[1].toFixed(7)) +
                                    "]"
                                ),
                              ]),
                              _c("i", {
                                staticClass: "el-icon-edit",
                                on: {
                                  click: function ($event) {
                                    return _vm.editPointHandle(item, index)
                                  },
                                },
                              }),
                              _c("i", {
                                staticClass: "el-icon-plus",
                                on: {
                                  click: function ($event) {
                                    return _vm.plusPoint(index)
                                  },
                                },
                              }),
                              _c("i", {
                                staticClass: "el-icon-delete",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.deletePoint(item, index)
                                  },
                                },
                              }),
                            ]
                          )
                        }
                      ),
                      0
                    )
                  : _c(
                      "div",
                      { staticClass: "edit-point-box" },
                      [
                        _c("div", { staticClass: "entity-line-index" }, [
                          _vm._v(_vm._s(_vm.formInPonits.index + 1)),
                        ]),
                        _c(
                          "el-form",
                          {
                            staticClass: "demo-form-inline",
                            attrs: { size: "mini", model: _vm.formInPonits },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: _vm.$t("longitude") } },
                              [
                                _c("el-input-number", {
                                  attrs: { size: "mini", controls: false },
                                  model: {
                                    value: _vm.formInPonits.lon,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formInPonits, "lon", $$v)
                                    },
                                    expression: "formInPonits.lon",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: _vm.$t("latitude") } },
                              [
                                _c("el-input-number", {
                                  attrs: { size: "mini", controls: false },
                                  model: {
                                    value: _vm.formInPonits.lat,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formInPonits, "lat", $$v)
                                    },
                                    expression: "formInPonits.lat",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  { on: { click: _vm.cancelEditHandle } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("airportManager.cancelButton")
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      loading: _vm.saveLoading,
                                      type: "primary",
                                    },
                                    on: { click: _vm.saveEditHandle },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("airportManager.saveButton")
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }