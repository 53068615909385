<template>
    <!-- 新增上传禁飞区 -->
    <el-dialog class="importDialog" title="导入禁飞区" :visible.sync="importDialog" width="450px"
        :modal-append-to-body="false" :close-on-click-modal="false" top="20vh" :before-close="handleCloseImport">
        <div class="content">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto" class="demo-ruleForm">
                <el-form-item :label="$t('noFlyZone.fileFormat') + '：'" prop="fileFormat">
                    <el-select v-model="ruleForm.fileFormat" size="small" style="width: 100%;" 
                        :placeholder="$t('noFlyZone.fileFormat')" @change="changeFileFormat">
                        <el-option label="geojson" value="geojson"></el-option>
                        <el-option label="kml" value="kml"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('noFlyZone.fileName') + '：'" prop="fileName">
                    <div class="choose-file">
                        <div class="file-name">
                            <el-input v-model="ruleForm.fileName" style="width: 100%;" size="small" 
                                disabled :placeholder="$t('noFlyZone.fileNamePlaceholder')" />
                        </div>
                        <div class="choose-btn">
                            <el-button type="primary" size="small" @click="chooseFiles">{{$t('chooseFile')}}</el-button>
                            <input style="display:none" type="file" ref="nofly-upload" 
                                :accept="acceptType" @change="handleFiles($event)" />
                        </div>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="handleCloseImport">{{$t('cancelText')}}</el-button>
            <el-button size="small" type="primary" :loading="loading" @click="submitImport">{{$t('submitText')}}</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    name: 'add-no-fly-pop',
    data() {
        return {
            loading: false,
            importDialog: false,
            ruleForm: {
                fileFormat: 'geojson',
                fileName: '',
                fileList: []
            },
            acceptType: '.geojson,.zip',
            rules: {
                fileFormat: [
                    {required: true, message: this.$t('noFlyZone.fileFormat'), trigger: 'change'}
                ],
                fileName: [
                    {required: true, message: this.$t('noFlyZone.fileFormat'), trigger: 'change'}
                ]
            }
        }
    },
    methods: {
        changeFileFormat() {
            if (this.ruleForm.fileFormat === 'geojson') {
                this.acceptType = '.geojson,.zip';
                return;
            }
            this.acceptType = '.kml,.zip';
        },
        chooseFiles() {
            this.$refs['nofly-upload'].value = '';
            this.$refs['nofly-upload'].click();
        },
        handleFiles(e) {
            this.ruleForm.fileName = '';
            this.ruleForm.fileList = [];
            let files = e.target.files;
            if (!files) return;
            for (let i = 0; i < files.length; i++) {
                this.ruleForm.fileName = i === files.length - 1 ? `${this.ruleForm.fileName}${files[i].name}` 
                    : `${this.ruleForm.fileName}${files[i].name}，`;
                this.ruleForm.fileList.push(files[i]);
            }
        },
        handleCloseImport() {
            this.importDialog = false;
            this.$refs['ruleForm'].resetFields();
            this.ruleForm = {
                fileFormat: 'geojson',
                fileName: '',
                fileList: []
            };
        },
        handleOpenImport() {
            this.importDialog = true;
            this.ruleForm = {
                fileFormat: 'geojson',
                fileName: '',
                fileList: []
            };
        },
        submitImport() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid && this.ruleForm.fileList && this.ruleForm.fileList.length) {
                    this.loading = true;
                    this.$emit('upLoadEmit', {data: this.ruleForm, callback: this.handleCloseImport});
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.importDialog {
    ::v-deep .el-dialog {
        background-color: rgba(30, 34, 42, 1);
        .el-dialog__header {
            .el-dialog__title {
                font-size: 16px;
                color: #FFFFFF;
            }
            .el-dialog__headerbtn .el-dialog__close {
                color: #ffffff;
            }
        }
        .el-dialog__body {
            padding: 15px 20px;
            .content {
                .el-form-item__label {
                    color: #ffffff;
                }
                .el-input__inner {
                    font-size: 14px;
                    color: #ffffff;
                    background-color: transparent;
                    &::placeholder {
                        color:  rgba(153, 153, 153, 0.79);
                    }
                }
                .choose-file {
                    display: flex;
                    align-items: center;
                    .file-name {
                        flex: 1;
                    }
                    .choose-btn {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
</style>